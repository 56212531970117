<template>
  <div>
    <Modal
      :value="value"
      title="养殖户详细信息"
      fullscreen
      @on-visible-change="modalChange"
      @input="(val) => $emit('input', val)"
    >
      <!-- 包含养殖户名称、编码、注册地址、是否种养一体、养殖场数量、规模及信息、养殖户联系人、联系方式等信息 -->
      <Form :label-width="100" style="display: flex">
        <div class="form-title" style="width: 200px">
          <h4>企业信息</h4>

          <FormItem>
            <img
              v-if="data.logo != ''"
              :src="logSrc"
              alt=""
              width="100"
              height="100"
              @click="lookPicture(logSrc)"
            />
            <img
              v-else
              src="/imgs/empty/empty.png"
              width="100"
              height="100"
              alt=""
            />
          </FormItem>

          <div style="width: 100%; padding-left: 20px">公司logo</div>
        </div>

        <Row>
          <Col span="8">
            <FormItem label="企业名称：">
              <Input
                readonly
                style="width: 260px"
                v-model="data.farmerName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="编码：">
              <Input
                readonly
                style="width: 260px"
                v-model="data.codeValue"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="企业类型：" style="padding-left: 60px">
              <Input
                readonly
                style="width: 260px"
                v-model="data.enterpriseTypeName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="地址：">
              <Input
                readonly
                style="width: 260px"
                v-model="data.farmerAddress"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="种养一体：">
              <Input
                readonly
                style="width: 260px"
                :value="data.isFarmPlant == 1 ? '是' : '否'"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="省市区编码：" style="padding-left: 60px">
              <Input
                readonly
                style="width: 260px"
                v-model="data.regionCodeName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="养殖场数量：">
              <Input
                readonly
                style="width: 260px"
                :value="data.farmCount + '个'"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="养殖品种：">
              <Input
                readonly
                style="width: 260px"
                v-model="data.typeName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="预计年产量：" style="padding-left: 60px">
              <Input
                readonly
                style="width: 260px"
                v-model="data.ceilingCount"
              ></Input>
            </FormItem>
          </Col>

          <Col span="8">
            <FormItem label="联系人：">
              <Input readonly style="width: 260px" v-model="data.name"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="联系电话：">
              <Input readonly style="width: 260px" v-model="data.tel"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="经营者身份证号码" :label-width="160">
              <Input
                readonly
                style="width: 260px"
                v-model="data.idCard"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="畜禽代码">
              <Input
                readonly
                style="width: 260px"
                v-model="data.livestockCode"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="法人代表">
              <Input
                readonly
                style="width: 260px"
                v-model="data.legalName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="统一社会信用代码：" :label-width="160">
              <Input
                readonly
                style="width: 260px"
                v-model="data.creditCode"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="法人身份证">
              <Input
                readonly
                style="width: 260px"
                v-model="data.legalCard"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="法人手机号">
              <Input
                readonly
                style="width: 260px"
                v-model="data.legalPhone"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="法人代表固话" style="padding-left: 60px">
              <Input
                readonly
                style="width: 260px"
                v-model="data.legalTel"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="生产状态">
              <Input
                readonly
                style="width: 260px"
                v-model="data.productionStatus"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="环保备案">
              <Input
                readonly
                style="width: 260px"
                v-model="data.environmentalProtection"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="动物防疫合格证" :label-width="160">
              <Input readonly style="width: 260px" v-model="data.aepc"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="监管人员名称">
              <Input
                readonly
                style="width: 260px"
                v-model="data.supervisorName"
              ></Input>
            </FormItem>
          </Col>

          <Col span="8">
            <FormItem label="监管人电话">
              <Input
                readonly
                style="width: 260px"
                v-model="data.supervisorPhone"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="种畜禽经营许可证" :label-width="160">
              <Input
                readonly
                style="width: 260px"
                v-model="data.blapbl"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="排污许可证">
              <Input
                readonly
                style="width: 260px"
                v-model="data.dischargePermit"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="规模类型">
              <Input
                readonly
                style="width: 260px"
                v-model="data.scaleInfo"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="养殖用途" style="padding-left: 60px">
              <Input
                readonly
                style="width: 260px"
                v-model="data.application"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="备注">
              <Input
                readonly
                style="width: 260px"
                type="textarea"
                v-model="data.remark"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>

      <!-- 基地信息 -->
      <div v-for="(item, index) in info" :key="index" style="margin: 20px 0">
        <Form :label-width="100" style="display: flex">
          <div class="form-title" style="width: 145px">
            <h4>{{ item.siteType == 1 ? "种植基地信息" : "养殖基地信息" }}</h4>

            <FormItem>
              <img
                v-if="item.imgUrl != ''"
                :src="servicePath + item.imgUrl"
                @click="lookPicture(servicePath + item.imgUrl)"
                alt=""
                width="100"
                height="100"
              />
              <img
                v-else
                src="/imgs/empty/empty.png"
                width="100"
                height="100"
                alt=""
              />
            </FormItem>

            <div style="width: 100%; text-align: center"></div>
          </div>

          <Row style="margin-top: 20px">
            <Col span="8" style="padding-left: 37px">
              <FormItem
                :label="
                  item.siteType == 1 ? '种植基地名称：' : '养殖基地名称：'
                "
                :label-width="120"
              >
                <Input
                  v-model="item.name"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="联系人：">
                <Input
                  v-model="item.chargePerson"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="联系电话：">
                <Input
                  v-model="item.chargePersonTel"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8" style="padding-left: 37px">
              <FormItem label="基地面积：" :label-width="120">
                <Input v-model="item.area" readonly style="width: 260px">
                  <span slot="append">㎡</span>
                </Input>
              </FormItem>
            </Col>

            <!-- <Col span="8">
              <FormItem label="所属区域：">
                <Input
                  v-model="item.region"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col> -->
            <Col span="8">
              <FormItem label="详细地址：">
                <Input
                  v-model="item.address"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="基地绘制：">
                <Input v-model="item.mapPosition" disabled style="width: 260px">
                  <span slot="append" class="iconfont" style="cursor: pointer"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span="2"></Col>
          <Col style="22">
            <Table
              v-if="item.siteType == 2"
              :columns="columns1"
              :data="data1"
              :width="1200"
            ></Table>
            <Table
              v-if="item.siteType == 1"
              :columns="columns2"
              :data="data1"
              :width="1200"
            ></Table>
          </Col>
        </Row>
      </div>

      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => $emit('input', false)"
          >确定</Button
        >
      </p>
    </Modal>

    <!-- 种植档案 -->
    <Modal
      v-model="flag"
      title="种植档案"
      fullscreen
      @on-visible-change="modalChange1"
    >
      <x-table
        :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
        :columns="columns3"
        :data="data3"
        @page-change="pageChange"
      ></x-table>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (this.flag = false)">确定</Button>
      </p>
    </Modal>

    <!-- 施肥档案 -->
    <Modal v-model="flag1" title="施肥档案" fullscreen>
      <x-table
        :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
        :columns="columns4"
        :data="data4"
        @page-change="pageChangeTwo"
      ></x-table>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (this.flag1 = false)">确定</Button>
      </p>
    </Modal>

    <!-- 存栏记录 -->
    <Modal v-model="flag2" title="存栏记录" fullscreen>
      <x-table
        :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
        :columns="columns5"
        :data="data2"
      ></x-table>
      <div style="text-align: right; margin-top: 10px">
        <Page
          :current="page2.pageNo"
          :page-size="page2.pageSize"
          :total="page2.total"
          @on-change="pageChange2"
        ></Page>
      </div>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (this.flag2 = false)">确定</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import { Col } from "view-design";
import pictureView from "@/components/picture_view";
export default {
  components: {
    pictureView,
  },
  data() {
    return {
      data: [],
      data3: [], //种植档案
      data4: [], //施肥档案
      data2: [], //存栏记录
      flag: false,
      logSrc: "",
      info: [],
      baseImgSrc: "",
      picture: {
        show: false,
        src: "",
      },
      columns1: [
        {
          title: "养殖区图片",
          minWidth: 100,
          render: (h, { row }) => {
            if (!row.imgUrl) {
              return <span>--</span>;
            }
            return (
              <div style="display: flex">
                {row.imgUrl.split(",").map((item) => {
                  return (
                    <img
                      src={this.getImgUrl(this.servicePath, item)}
                      onClick={() => this.lookPicture(this.servicePath + item)}
                      style="margin-right: 10px;width:30px;height:30px;cursor:pointer"
                    />
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "所属养殖区",
          minWidth: 80,
          key: "name",
        },
        {
          title: "养殖区面积",
          minWidth: 80,
          render: (h, { row }) => {
            return <span>{row.area + "㎡"}</span>;
          },
        },
        {
          title: "存栏量",
          minWidth: 100,
          key: "count",
        },
        {
          title: "均重(kg)",
          width: 100,
          key: "avgWeight",
        },
        {
          title: "备注",
          minWidth: 100,
          key: "remark",
        },
        {
          title: "操作",
          width: 200,
          render: (h, { row }) => {
            return (
              <div>
                {this.CA("plant_edit") && (
                  <a
                    style="margin-right:20px"
                    on-click={() => this.lohRecord(row)}
                  >
                    存栏记录
                  </a>
                )}
              </div>
            );
          },
        },
      ],
      data1: [],
      columns2: [
        {
          title: "地块编号",
          key: "name",
        },
        {
          title: "地块面积",
          key: "avgWeight",
        },
        {
          title: "地力等级",
          key: "areaLevel",
        },
        {
          title: "备注",
          key: "name",
        },
        {
          title: "操作",
          width: 200,
          render: (h, { row }) => {
            return (
              <div>
                {this.CA("plant_edit") && (
                  <a
                    style="margin-right:20px"
                    on-click={() => this.plantingFile(row)}
                  >
                    种植档案
                  </a>
                )}
                {this.CA("plant_edit") && (
                  <a
                    style="margin-right:20px"
                    on-click={() => this.fertilizerFile(row)}
                  >
                    施肥档案
                  </a>
                )}
              </div>
            );
          },
        },
      ],
      //种植档案
      columns3: [
        {
          title: "所属地块",
          key: "sceneName",
        },
        {
          title: "种植品种",
          key: "categoryName",
        },
        {
          title: "种植方式",
          key: "plantName",
        },
        {
          title: "预计结束时间",
          key: "name",
        },
        {
          title: "粪肥供给养分占比",
          key: "name",
          render: (h, { row }) => {
            return <span>{row.fertilizationSupplyPercentage * 100} %</span>;
          },
        },
        {
          title: "粪肥占施肥比列",
          key: "name",
          render: (h, { row }) => {
            return <span>{row.manureFertilizationPercentage * 100} %</span>;
          },
        },
        {
          title: "作物元素",
          key: "elementName",
        },
        {
          title: "状态",
          key: "statusName",
        },
      ],
      //施肥档案
      columns4: [
        {
          title: "施用基地",
          key: "farmName",
        },
        {
          title: "施用地块",
          key: "sceneName",
        },
        {
          title: "施用量",
          key: "amount",
          render: (h, { row }) => {
            return <span>{row.amount}kg</span>;
          },
        },
        {
          title: "施用时间",
          key: "applyTime",
          render: (h, { row }) => {
            let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
            return <span>{str}</span>;
          },
        },
      ],
      //存栏记录
      columns5: [
        {
          title: "养殖区",
          minWidth: 100,
          render: (h, { row }) => {
            return <span>{row.farmName + "/" + row.sceneName}</span>;
          },
        },
        {
          title: "养殖品种",
          minWidth: 100,
          key: "categoryName",
        },
        {
          title: "变更数量(头)",
          minWidth: 100,
          key: "num",
        },
        {
          title: "变更类型",
          minWidth: 100,
          render: (h, { row }) => {
            return <span>{row.type == 1 ? "增加" : "减少"}</span>;
          },
        },
        {
          title: "描述",
          minWidth: 100,
          key: "describes",
        },
        {
          title: "上报时间",
          minWidth: 100,
          key: "createTime",
        },
      ],
      flag3: false,
      flag1: false,
      flag2: false,
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      page1: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      //存栏记录分页
      page2: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      rowInfo: [],
      servicePath: "",
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farmersDetailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    farmListInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    servicePathInfo: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    modalChange(validate) {
      this.data = this.farmersDetailInfo;
      this.logSrc = this.getImgUrl(
        this.farmersDetailInfo.servicePath,
        this.farmersDetailInfo.logo
      );
      this.info = this.farmListInfo;
      this.farmListInfo.forEach((item) => {
        this.data1 = item.sceneList;
      });
      this.servicePath = this.servicePathInfo;
      if (this.info.imgUrl) {
        this.baseImgSrc = this.getImgUrl(this.servicePath, this.info.imgUrl);
      } else {
        this.baseImgSrc = "";
      }
      if (this.data.productionStatus == "1") {
        this.data.productionStatus = "正常";
      } else if (this.data.productionStatus == "2") {
        this.data.productionStatus = "临时性停车";
      } else if (this.data.productionStatus == "3") {
        this.data.productionStatus = "停产";
      }
    },
    //种植档案
    plantingFile(row) {
      this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        farmId: row.farmId,
        sceneId: row.sceneId,
        categoryId: row.categoryId,
        categoryName: row.categoryName,
        num: row.num,
      }).then((res) => {
        this.data3 = res.list;
        this.flag = true;
      });
    },
    //施肥档案
    fertilizerFile(row) {
      this.$post(this.$api.WASTE_APPLICATION.PLANT_LIST, {}).then((res) => {
        this.data4 = res.list;
        this.flag1 = true;
      });
    },
    pageChange(pageNo) {
      //   this.page.pageNo = pageNo;
    },
    pageChangeTwo(pageNo) {
      //   this.page.pageNo = pageNo;
    },
    pageChange2(pageNo) {
      this.page2.pageNo = pageNo;
      this.lohRecord(this.rowInfo);
    },
    modalChange1(visible) {},
    //存栏记录
    lohRecord(row) {
      this.rowInfo = row;
      this.$post(this.$api.LIVESTOCK_ON_HAND.RECORD_LIST, {
        pageSize: this.page2.pageSize,
        pageNo: this.page2.pageNo,
        farmId: row.farmId,
      }).then((res) => {
        this.data2 = res.list;
        this.flag2 = true;
        this.page2.total = +res.total;
      });
    },
    //放大查看图片
    lookPicture(url) {
      console.log(url);
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ div {
  .ivu-form-item {
    .ivu-form-item-content {
      margin-left: 0 !important;
    }
  }
  .form-title {
    padding: 10px 20px;
  }
  h4 {
    margin-bottom: 10px;
  }
}
</style>